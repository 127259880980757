<template>
  <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 7.5H15.125C15.3321 7.5 15.5 7.66789 15.5 7.875V15" stroke="#222222" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.58807 17.375L8.07641 15.7436C8.03853 15.6228 8.12874 15.5 8.25531 15.5H16.7447C16.8713 15.5 16.9615 15.6228 16.9236 15.7436L16.4119 17.375" stroke="#222222" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 12.5H13" stroke="#222222" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.5 10.5H13" stroke="#222222" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DiadocIcon'
}
</script>
