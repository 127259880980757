<template>
  <svg class="arrow_down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5.718">
    <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M11.189,15.241l3.781-3.784a.712.712,0,0,1,1.009,0,.721.721,0,0,1,0,1.012L11.7,16.756a.713.713,0,0,1-.985.021L6.4,12.472a.715.715,0,1,1,1.009-1.012Z" transform="translate(-6.188 -11.246)"></path>
  </svg>
</template>

<style lang="stylus">

.arrow_down
  width 12px
  fill $dark

</style>

<script>
export default {
  name: 'ArrowDownIcon'
}
</script>
